:root {
	--base-01: 255, 255, 255;
	--base-02: 236, 243, 236;
	--base-03: 198, 207, 198;
	--base-04: 171, 180, 171;
	--base-05: 149, 157, 149;
	--base-06: 124, 135, 124;
	--base-07: 60, 66, 60;
	--base-08: 41, 47, 42;
	--base-09: 31, 37, 33;
	--base-10: 26, 30, 26;
	--base-11: 23, 28, 23;
	--base-12: 0, 0, 0;
	--primary-accent-green: 57, 245, 106;
	--primary-accent-green-light: 192, 249, 200;
	--primary-accent-yellow: 195, 255, 0;
	--primary-accent-blue: 58, 61, 255;
	--primary-accent-grey: 41, 47, 42;
	--primary-accent-grey-light: 60, 66, 60;
	--primary-accent-grey-dark: 26, 30, 26;
	--social-media-light-text: 117, 117, 117;
	--social-media-light-text-bg: 242, 243, 247;
	--primary: var(--primary-accent-green);
	--primary-light: var(--primary-accent-green-light);
	--primary-dark: var(--primary-accent-green-dark);
	--secondary: var(--primary-accent-green);
	--secondary-light: var(--primary-accent-pink-light);
	--secondary-dark: var(--primary-accent-pink-dark);

	// Font Colors
	--font-brand-01: var(--primary-accent-green);
	--font-brand-light: var(--primary-accent-green-light);
	--font-dark: var(--base-11);
	--font-light: 249, 249, 249;
	--font-supportive: var(--base-05);
	--font-disabled: var(--base-07);

	// System Colors
	--error: 246, 75, 75;
	--success: 0, 199, 107;
	--info: 0, 130, 235;
	--pending: 249, 174, 65;
	--purple: 168, 100, 255;

	// Live Casino
	--active-players-color: 248, 249, 253;
	--full-room-color: 111, 112, 116;

	// Notification Inbox
	// Notification counter
	--unread-message-counter-bg-color: var(--primary-accent-yellow);
	--unread-message-counter-text-color: var(--font-dark);

	// Notification Header
	--my-notification-category-color: var(--font-light);
	--my-notification-border-bottom: var(--primary-accent-grey-light);
	--my-notifications-header-bg-color: var(--primary-accent-grey);
	// --my-notifications-back-button-filter: var(--filter-secondary);
	--my-notifications-heading-color: var(--font-light);
	--my-notifications-mark-all-read-color: var(--primary-accent-green);
	--my-notifications-back-button-color: rgba(var(--primary-accent-green), 1);
	// Notification: Unread message
	--unread-message-title-slider-not-active: var(--font-light);
	--unread-message-notifier-color: var(--primary-accent-green-light);
	--unread-message-title-color: var(--primary-accent-green);
	--unread-message-subtitle-color: var(--base-05);
	--unread-message-date-color: var(--base-05);

	// Notification: Read message
	--read-message-color: var(--font-supportive);
	--read-message-title-color: var(--font-supportive);

	// Notification: No Data
	--no-notifications-icon-color: var(--base-01);
	--no-notifications-heading-color: var(--base-02);
	--no-notifications-subheading-color: var(--base-06);

	// Notification: Full screen popup
	--notification-popup-bg: var(--base-07);
	--notification-popup-blur-bg: var(--base-12);
	--notification-popup-title: var(--base-02);
	--notification-popup-subtitle: var(--base-02);
	--notification-popup-close-icon: var(--primary-accent-green);
	--notification-popup-button-bg: var(--primary-accent-green);
	--notification-popup-button-text: var(--base-11);
	--notification-popup-dot-active: var(--primary-accent-green);
	--notification-popup-dot: var(--primary-accent-green-light);

	// Web-push
	--web-push-popup-bg: var(--base-07);
	--web-push-popup-title: var(--font-light);
	--web-push-popup-subtitle: var(--font-light);
	--web-push-popup-button-bg: var(--primary-accent-green);
	--web-push-popup-button-text: var(--primary-accent-green);

	// Source Of Wealth
	--source-of-wealth-help-text: var(--base-02);
	--source-of-wealth-form-bg: var(--base-10);
	--source-of-wealth-form-text: var(--base-02);
	--source-of-wealth-dropdown-bg: var(--base-11);
	--source-of-wealth-dropdown-border: var(--base-10);

	//embed link
	--embed-link-color: var(--primary-accent-green);

	// Progress Meter
	--risk-level-base: var(--base-10);
	--risk-level-0: var(--base-10);
	--risk-level-1: var(--success);
	--risk-level-2: var(--success);
	--risk-level-3: var(--pending);
	--risk-level-4: var(--error);

	//  Type Vars
	--display: normal normal normal 7rem 'Gravity Extra Condensed Italic', sans-serif;
	--display-2: normal normal normal 5rem/1.2 'Gravity Extra Condensed Italic', sans-serif;
	--display-3: normal normal normal 3.6rem/1.222 'Gravity Extra Condensed Italic', sans-serif;
	--h1: normal normal normal 2.8rem/1.285 'Gravity Extra Condensed Italic', sans-serif;
	--h2: normal normal normal 2.2rem/1.272 'Gravity Extra Condensed Italic', sans-serif;
	--h3: normal normal 700 1.9rem/1.265 'Open Sauce One', sans-serif;
	--h4-bold: normal normal 700 1.7rem/1.412 'Open Sauce One', sans-serif;
	--h4-regular: normal normal 400 1.7rem/1.412 'Open Sauce One', sans-serif;
	--heading-5: normal normal 700 1.6rem/1.4375 'Open Sauce One', sans-serif;
	--label-regular: normal normal 400 1.5rem/1.333 'Open Sauce One', sans-serif;
	--label-regular-tc: normal normal 400 1.5rem/1.066 'Open Sauce One', sans-serif;
	--label-bold: normal normal 700 1.5rem/1.333 'Open Sauce One', sans-serif;
	--caption: normal normal 400 1.2rem/1.333 'Open Sauce One', sans-serif;
	--caption-bold: normal normal 700 1.2rem/1.333 'Open Sauce One', sans-serif;
	--small: normal normal 400 1.1rem/1.272 'Open Sauce One', sans-serif;
	--small-bold: normal normal 700 1.1rem/1.272 'Open Sauce One', sans-serif;

	// Font Changes 4
	--top-right-corner-link: var(--label-bold);
	--product-icon-text: var(--label-bold);
	--footer-links: var(--label-bold);
	//--footer-flag-language-text: defaults to --label-regular;
	--top-right-corner-link-padding: 0;
	--footer-link-continue-icon-color: rgba(var(--primary-accent-green), 1);

	// Top Navigation
	--top-navigation-item-color: var(--font-light);
	--top-navigation-item-color-selected: var(--primary);
	--top-navigation-item-label: var(--caption-bold);

	// Bottom Navigation
	--bottom-navigation-item-color: var(--font-light);
	--bottom-navigation-item-color-selected: var(--primary);
	--bottom-navigation-item-label: var(--caption-bold);

	// Landing Page
	--landing-default-bg-color: rgba(var(--base-11), 1);
	--login-btn-bg: rgba(var(--primary-accent-grey-light), 1);
	--login-btn-text: rgba(var(--primary-accent-green-light), 1);
	// --landing-page-logo: defaults to --base-01
	--landing-page-title: rgba(var(--font-light), 1);
	--landing-page-cta-btn-bg: rgba(var(--font-brand-01), 1);
	--landing-page-cta-btn-color: rgba(var(--font-dark), 1);
	--product-icon-bg: rgba(var(--primary-accent-green-light), 1);
	--product-icon: rgba(var(--font-dark), 1);
	--product-icon-label: rgba(var(--font-light), 1);
	--terms-and-condition_bg: rgba(var(--base-12), 0.8);
	--bonus-terms-text: rgba(var(--font-light), 1);
	--bonus-terms-link: rgba(var(--primary-accent-green), 1);
	--reg-bonus-text-active: rgba(var(--font-dark), 1);
	--welcome-popup-bg: rgba(var(--primary-accent-grey), 1);
	--welcome-popup-text-color: rgba(var(--font-supportive), 1);
	--welcome-popup-text-label: rgba(var(--font-light), 1);
	--welcome-popup-progress-color: rgba(var(--primary-accent-green-light), 1);
	--welcome-popup-link-color: rgba(var(--primary-accent-green), 1);
	--welcome-popup-separator: rgba(var(--primary-accent-grey-dark), 1);
	--welcome-popup-background-circle-fill: rgba(var(--base-07), 1);

	// Head
	--head-bg: rgba(var(--base-10), 1);
	--page-head-logo-height: 26px;
	// --page-head-logo: defaults to --base-01
	// --page-head-title: defaults to --base-01
	// --account-text:  defaults to --base-01
	--account-icon: rgba(var(--base-01), 1);
	--balance-circle: rgba(var(--primary-accent-grey), 1);
	--shop-balance-bg: rgba(var(--primary-accent-grey), 1);
	--balance-icon-wrapper: rgba(var(--primary-accent-green), 1);
	--balance-icon-wrapper-basic: rgba(var(--base-10), 1);
	--balance-shop-animate-points-wrapper: rgba(var(--base-09), 1);
	// --balance-text:  defaults to --base-01
	// --balance-icons: defaults to --base-01
	--balance-header-icon: rgba(var(--primary-accent-grey-light), 1);
	--quick-deposit-icon-color: rgba(var(--base-11), 1);
	--balance-fill-icon-color: rgba(var(--base-01), 1);
	--page-head-link: rgba(var(--primary-accent-green), 1);
	--page-head-icon: rgba(var(--primary-accent-green), 1);
	--hamburger-menu: rgba(var(--primary-accent-green), 1);

	// Chip
	--balance-chip-circle: rgba(var(--primary-accent-grey-dark), 1);
	--balance-chip-icon: rgba(var(--font-light), 1);
	--balance-icon-chip-wrapper: rgba(var(--primary-accent-grey-light), 1);
	--balance-chip-text: rgba(var(--font-light), 1);

	// Regulations Header
	--panic-wrapper-bg: rgba(var(--base-09), 1);
	//--activeProductBg:  defaults to --info
	--notActiveProductBg: rgba(var(--base-11), 1);
	--notActiveProductSvg: rgba(var(--base-02), 1);
	// --cool-off-timer-color: defaults to --base-02
	--reg-header-dga-background: var(--primary-accent-grey-dark);
	// --reg-header-dga-border: var(--base-12);
	--reg-header-dga-color: var(--font-light);

	// SGA Header
	--sga-head-bg-color: rgba(var(--primary-accent-grey-dark), 1);
	//--sga-head-icon-bg: defaults to --base-01;
	//--sga-head-border: defaults to none;
	--sga-head-border-bottom-color: solid 1px rgba(var(--base-08), 1);

	// Buttons
	--button-primary-bg: rgba(var(--primary-accent-green), 1);
	--button-primary-bg-hover: rgba(var(--primary-accent-green-light), 1);
	--button-primary-text-hover: rgba(var(--font-dark), 1);
	--button-primary-border: transparent;
	--button-primary-text: rgba(var(--font-dark), 1);
	--button-secondary-bg: rgba(var(--primary-accent-grey-light), 1);
	--button-secondary-text: rgba(var(--font-light), 1);
	--button-wrapper-bg: rgba(var(--primary-accent-grey), 1);
	--button-wrapper-bg-light: rgba(var(--base-02), 1);
	// --button-register: defaults to --primary-accent-green
	--button-register-text: rgba(var(--font-dark), 1);
	--button-register-border-radius: 100px;
	--button-pag: rgba(var(--primary-accent-grey-dark), 1);
	--button-pag-text: rgba(var(--primary-accent-green), 1);
	--support-button: rgba(var(--primary-accent-grey-light), 1);
	--support-button-content: rgba(var(--primary-accent-green-light), 1);
	--button-border-radius: 100px;
	--button-disabled-bg: rgba(var(--primary-accent-grey-light), 1);
	--button-disabled-text: rgba(var(--font-supportive), 1);
	--buttonLargeText: var(--base-04);
	--buttonLargeTextHover: var(--base-02);
	--buttonLargeBg: var(--primary-accent-green);
	--button-primary-slider-bg-exeption: rgba(var(--primary-accent-grey), 1);
	--button-primary-slider-text-exeption: rgba(var(--primary-accent-green-light), 1);
	--button-success-bg: rgba(var(--base-11), 0.25);
	--button-contrast-border: rgba(var(--base-01), 1);
	--button-play-as-guest-border: 1px solid rgba(var(--primary-accent-green), 1);
	--brand-border-radius: 100px;

	// Badges
	--badge-primary-bg: rgba(var(--primary-accent-green), 1);
	--badge-primary-text: rgba(var(--font-dark), 1);
	--badge-secondary-bg: rgba(var(--primary-accent-yellow), 1);
	--badge-secondary-bg-active: rgba(var(--primary-accent-pink-light), 1);
	--badge-secondary-text: rgba(var(--font-dark), 1);
	--badge-new-bg: rgba(var(--primary-accent-yellow), 1);
	--badge-new-text: rgba(var(--font-dark), 1);
	--badge-dark-bg: rgba(var(--primary-accent-yellow), 1);
	--badge-dark-text: rgba(var(--font-dark), 1);

	// Select
	--select-border-bottom-color: rgba(var(--base-07), 1);
	--select-selected-option-color: rgba(var(--base-01), 1);
	--select-option-color: rgba(var(--base-11), 1);
	--select-option-bg-color: rgba(var(--base-01), 1);
	--select-legend-color: rgba(var(--base-06), 1);
	--select-arrow-down-color: rgba(var(--primary-accent-green), 1);

	// Odds format toggle
	--odds-format-toggle-bg: rgba(var(--primary-accent-grey), 1);

	// Pill
	--pill-bg: rgba(var(--primary-accent-grey), 1);
	--pill-label-color: rgba(var(--font-brand-light), 1);
	--pill-checked-bg: rgba(var(--primary-accent-green-light), 1);
	--pill-checked-label-color: rgba(var(--font-dark), 1);

	// Link List
	--link-list: rgba(var(--primary-accent-grey-dark), 1);

	// Borders
	--list-border-bottom: rgba(var(--primary-accent-grey), 1);

	// Filters 12
	--filter-primary: invert(55%) sepia(100%) saturate(391%) hue-rotate(33deg) brightness(97%) contrast(91%);
	--filter-bg: rgba(var(--base-10), 1);
	--filter-border-primary: 1px solid rgba(var(--primary-accent-grey), 1);
	--filter-bg-secondary: rgba(var(--base-10), 1);
	// --filter-border-secondary: defaults to --base-09
	--filter-idle: rgba(var(--base-02), 1);
	--filter-idle-secondary: rgba(var(--base-02), 1);
	--filter-disabled: rgba(var(--base-08), 1);
	--filter-active: rgba(var(--primary-accent-green), 1);
	--filter-active-secondary: rgba(var(--primary-accent-green), 1);
	--filter-bar-active: rgba(var(--primary-accent-green), 1);
	--filter-bar-active-secondary: rgba(var(--primary-accent-green), 1);
	--filter-bar-inactive: rgba(var(--base-08), 1);
	// --filter-hover: defaults to --base-01
	// --filter-hover-secondary: defaults to nothing
	--filter-support-links: rgba(var(--font-supportive), 1);
	--filter-bar-bottom-border: rgba(var(--primary-accent-grey-light), 1);

	//Mobile search input field
	--search-bg: rgba(var(--primary-accent-grey), 1);
	--search-text: rgba(var(--font-supportive), 1);
	--search-text-small: rgba(var(--primary-accent-green), 1);
	--search-icon: rgba(var(--primary-accent-green), 1);
	--search-icon-bg-color: var(--submenu-item-bg);
	--search-cancel-font: var(--label-regular);
	--search-cancel-color: var(--font-light);

	//Mobile search results
	--search-results-bg: rgba(var(--base-10), 1);
	--search-result-item-bg: rgba(var(--primary-accent-grey-light), 1);
	--search-result-item-bg-secondary: rgba(var(--primary-accent-grey-dark), 1);
	--search-results-caption: rgba(var(--font-light), 1);
	--search-results-caption-font: var(--label-regular);
	--search-results-more: rgba(var(--primary-accent-green), 1);
	--search-no-results-text-font: var(--caption);
	--search-no-results-text-color: rgba(var(--font-light), 1);
	--search-ph-gradient-bottom: rgba(var(--base-09), 0.3);
	--search-ph-gradient-top: rgba(var(--base-09), 1);
	--search-ph-mask: rgba(var(--base-11), 1);

	//Desktop search input field
	--search-bg-large: rgba(var(--primary-accent-grey), 1);
	--search-text-large: rgba(var(--primary-accent-green), 1);
	--search-text-desktop-placeholder: rgba(var(--font-supportive), 1);
	--search-icon-large: rgba(var(--primary-accent-green), 1);

	//Desktop search results
	--search-no-results-desktop-text-font: var(--h3);
	--search-no-results-desktop-text-color: rgba(var(--font-light), 1);

	//Custom checkboxes
	--custom-checkbox-border-color: var(--base-06);
	--custom-checked-checkbox-bg: var(--primary-accent-green);
	--custom-checked-checkbox-border-color: var(--primary-accent-green);
	--custom-checked-checkmark-color: var(--base-12);
	--custom-checked-disabled-checkbox-bg: var(--base-07);
	--custom-checked-disabled-checkbox-border-color: var(--base-07);
	--custom-checked-disabled-checkbox-checkmark: var(--base-12);

	// Custom radio buttons
	--custom-radio-border: var(--base-06);
	--custom-checked-radio-bg: var(--primary-accent-green);
	//--custom-checked-radio-border-color: defaults to primary
	--custom-disabled-radio-border-color: var(--base-07);
	--custom-checked-disabled-radio-bg: var(--base-07);
	--custom-checked-disabled-radio-border-color: var(--base-07);

	// Custom select/dropdown
	--custom-select-icon: rgba(var(--font-light), 1);
	--custom-select-icon-option: rgba(var(--primary-accent-green), 1);
	--custom-select-options-bg: rgba(var(--base-08), 1);
	--custom-select-options-icon: rgba(var(--primary-accent-green), 1);
	--custom-select-options-icon--selected: rgba(var(--primary-accent-green), 1);
	--custom-select-split-line-color: rgba(var(--base-10), 1);

	// Section Head
	--main-icon: rgba(var(--base-02), 1);
	--main-title: rgba(var(--font-light), 1);
	--main-subtitle: rgba(var(--font-supportive), 1);
	--inverted-main-icon: rgba(var(--base-02), 1);
	--inverted-main-title: rgba(var(--base-02), 1);
	--inverted-main-subtitle: rgba(var(--base-02), 1);
	--section-head-main-icon: rgba(var(--primary-accent-green-light), 1);
	--deposit-confirmation-icon-color: rgba(var(--base-02), 1);

	// Game Tags
	--hot-bg: rgba(var(--primary-accent-green), 1);
	--new-bg: rgba(var(--primary-accent-yellow), 1);
	--hot-text: rgba(var(--font-dark), 1);
	--new-text: rgba(var(--font-dark), 1);
	--game-tag-background-color--EXCLUSIVE: rgba(var(--primary-accent-green), 1);
	--game-tag-text-color--EXCLUSIVE: rgba(var(--font-dark), 1);
	--provider-name-line-height: 15px;
	--game-tag-line-height: 17px;
	--active-players-line-height: 15px;
	--stakes-pill-line-height: 17px;

	// Sign In
	--sign-in-box: rgba(var(--base-11), 1);
	// --sign-in-box-border: defaults to --base-09

	// Tabs
	--product-tab: rgba(var(--primary-accent-grey), 1);
	--product-tab-content: rgba(var(--base-05), 1);
	--tab-text-color-active: rgba(var(--primary-accent-green), 1);
	--product-tab-border-top: rgba(var(--primary-accent-grey-light), 1);

	// Sidebar
	--sidebar-bg: rgba(var(--primary-accent-grey), 1);
	--sidebar-box: rgba(var(--base-10), 1);
	--sidebar-shadow: rgba(var(--base-12), 0.2);
	// --sidebar-border-left: defaults to the same color as sidebar-bg

	// Payments
	--spinner-loading-animation: rgba(var(--base-02), 1);

	// Footer
	--footer-icon: rgba(var(--base-05), 1);
	--footer-bg: rgba(var(--base-10), 1);
	--footer-box: rgba(var(--primary-accent-grey), 1);
	--footer-text: var(--font-supportive);
	--language-text: rgba(var(--font-light), 1);
	--footer-payment-icon: rgba(var(--base-05), 1);
	--footer-payment-logo-bg: rgba(var(--base-01), 1);
	--footer-social-icon: rgba(var(--base-05), 1);
	--footer-18-icon: rgba(var(--error), 1);
	--footer-18-icon-text: rgba(var(--font-light), 1);
	--footer-logo-18-height: 45px;
	--footer-logo-18-width: 45px;
	--flag-eu: rgba(var(--base-05), 1);
	--flag-eu-dots: rgba(var(--base-12), 1);
	--footer-link-color: rgba(var(--font-light), 1);
	--footer-link-category-title: rgba(var(--base-01), 1);
	--footer-link-category-item: rgba(var(--base-06), 1);
	--custom-legalny-bukmacher-logo-color: var(--footer-icon);

	// Hero
	--hero-bg: rgba(var(--base-09), 1);
	--hero-font-color: rgba(var(--base-02), 1);

	// SEO-content 3
	//--seo-read-more-btn: defaults to --primary-accent-green
	--seo-content-border-bottom: rgba(var(--base-09), 1);
	--seo-content-bg: rgba(var(--base-07), 1);

	// Card
	--box-bg: rgba(var(--base-10), 1);
	--browse-circle-bg: rgba(var(--primary-accent-grey-light), 1);
	// --browse-circle-icon: rgba(var(--base-02), 1); // defaults to primary
	--open-in-new-window-icon: var(--base-05);

	// Game card
	--bowtie-guy: rgba(var(--base-08), 1);

	// Loader
	--loading-animation-dots-color: rgba(var(--primary-accent-green), 1);

	//rgba(var(--base-02), 0.3)

	// Virtuals
	--virtuals-root-bg-color: rgba(var(--primary-accent-grey-dark), 1);

	// Highlight-games
	--highlight-games-bg-primary: rgba(var(--primary-accent-grey-dark), 1);
	--highlight-games-bg-secondary: rgba(var(--primary-accent-grey), 1);
	--highlight-games-bg-tertiary: rgba(var(--base-09), 1);
	--highlight-games-mobile-betslip-bg: rgba(var(--primary-accent-grey), 1);
	--highlight-games-text-primary: rgba(var(--base-02), 1);
	--highlight-games-text-secondary: rgba(var(--base-06), 1);
	--highlight-games-text-disabled: rgba(var(--base-02), 0.3);
	--highlight-games-text-secondary-disabled: rgba(var(--base-07), 1);
	--highlight-games-betslip-balance-wrapper: rgba(var(--primary-accent-grey), 1);
	--highlight-games-betslip-balance-icon-color: rgba(var(--base-02), 1);
	--highlight-games-border-color: rgba(var(--primary-accent-grey-light), 1);
	--highlight-games-events-bg: var(--highlight-games-bg-primary);
	--highlight-games-live-color: rgba(var(--primary-accent-yellow), 1);
	--highlight-games-live-text-color: var(--button-primary-text);
	--highlight-games-selection-btn-bg: var(--selection-button-color);
	--highlight-games-breadcrumbs-text-color: rgba(var(--base-06), 1);
	--highlight-games-breadcrumbs-text-color-hover: rgba(var(--font-light), 1);
	--highlight-games-navigation-button-color: rgba(var(--primary), 1);
	--highlight-games-btn-disabled: var(--selection-button-disabled-bg-color);
	--highlight-games-week-btn-bg: var(--selection-button-color);
	--highlight-games-week-btn-disabled: var(--selection-button-disabled-bg-color);
	--highlight-games-week-btn-text-disabled: var(--selection-button-odds-text-disabled);
	--highlight-games-playing-now-border: rgba(var(--primary-accent-grey-light), 1);
	--highlight-games-betslip-animated-border: rgba(var(--primary-accent-green), 1);

	// highlight games selection button
	--highlight-games-selection-button-color: rgba(var(--primary-accent-grey-light), 1);
	--highlight-games-selection-button-color-alternative: rgba(var(--primary-accent-grey-dark), 1);
	--highlight-games-selection-button-selection-text: rgba(var(--font-supportive), 1);
	--highlight-games-selection-button-disabled-bg-color: var(--selection-button-disabled-bg-color);

	// --highlight-games-betslip-header-text-color: defaults to rgba(var(--highlight-games-text-primary), 1);
	--highlight-games-video-player-background: rgba(var(--base-12));
	--highlight-games-bet-tab-label-text-color: rgba(var(--base-02), 1);
	--highlight-games-pending-bet-color: rgba(var(--pending), 1);
	--highlight-games-bet-body-bg-color: var(--highlight-games-bg-secondary); // TODO Remove from all brands
	--highlight-games-bet-betline-bg-color: var(--highlight-games-bg-primary);
	// --highlight-games-mobile-betslip-bg: defaults to var(--highlight-games-bg-tertiary);
	//--highlight-games-void-bet-color:defaults to rgba(var(--base-02);
	//--highlight-games-clear-bets-btn-color: defaults to rgba(var(--base-10), 1);
	// --highlight-games-market-count-text-color: defaults to rgba(var(--base-04), 1);
	// --highlight-games-bet-tab-text-color: rgba(var(--base-06), 1);
	// --highlight-games-bet-header-bg: defaults to --highlight-games-bg-tertiary
	--highlight-games-bet-tab-border-color: rgba(var(--primary-accent-green), 1);
	// --highlight-games-week-event-border: defaults to 0;
	// --highlight-games-week-event-hover-border: defaults to 0;
	//--highlight-games-week-event-label-color: defaults to --primary
	--highlight-games-week-header-onboarding-title: rgba(var(--primary-accent-green), 1);
	--highlight-games-carousel-dices: rgba(var(--base-02), 1);
	--highlight-games-event-icon-featured: rgba(var(--primary-accent-green), 1);
	--highlight-games-pending-bet-close: rgba(var(--primary-light), 1);
	//--highlight-games-pending-betslip-border-color: defaults to -highlight-games-bg-secondary
	--highlight-games-mute-icon-color: rgba(var(--primary-accent-green), 1);
	--highlight-games-back-link-icon: rgba(var(--base-02), 1);
	--hightlight-games-server-offline: rgba(var(--base-02), 1);
	--highlight-games-close-betslip-icon: rgba(var(--primary), 1);
	--highlight-games-betslip-combo-stake-input: var(--stake-input-bg-color);
	--highlight-games-betslip-pending-bet-stake-input: var(--stake-input-bg-color);
	--highlight-games-betslip-header-title-color: rgba(var(--font-light), 1);
	--highlight-games-minimized-betslip-header-title-color: rgba(var(--font-dark), 1);
	--highlight-games-minimized-betslip-header-bets-count-circle-color: rgba(var(--font-dark), 1);
	--highlight-games-betslip-header-bets-count-circle-color: rgba(var(--primary-light), 1);
	--highlight-games-minimized-betslip-header-bets-count-label-color: rgba(var(--font-light), 1);
	--highlight-games-betslip-header-bets-count-label-color: rgba(var(--font-dark), 1);
	--highlight-games-betslip-header-icon-color: rgba(var(--primary), 1);
	--highlight-games-minimized-betslip-header-icon-color: rgba(var(--font-dark), 1);
	--highlight-games-betslip-border-color: rgba(var(--primary-accent-grey-light), 1);
	--highlight-games-betslip-clear-button: rgba(var(--base-12), 1);
	--highlight-games-betslip-button-disabled-bg: rgba(var(--base-07), 1);
	--highlight-games-betslip-button-disabled-text: rgba(var(--font-supportive), 1);
	--highlight-games-betslip-bg: rgba(var(--primary-accent-grey), 1);
	--highlight-games-navigation-tab-border-color: rgba(var(--primary-accent-grey-light), 1);
	--highlight-games-navigation-tab-color: rgba(var(--font-supportive), 1);
	--highlight-games-navigation-tab-active-color: rgba(var(--primary), 1);

	// highligh games shadow
	--highlight-games-shadow-primary: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%),
		0 6px 30px 5px rgb(0 0 0 / 12%);

	// Onboarding
	// --highlight-games-bg-secondary: defaults to rgba(var(--base-11), 1)
	--onboarding-backdrop-bg: rgba(var(--base-11), 0.85);
	--onboarding-item-bg: rgba(var(--primary-accent-grey-dark), 1);
	--onboarding-navigation-dot-bg: rgba(var(--base-10), 1);
	--onboarding-navigation-dot-active-bg: rgba(var(--base-10), 1);
	--onboarding-border-radius: 3px;

	// StakeInput
	--stake-input-button-bg-color: rgba(var(--primary-accent-grey-dark), 1);
	--stake-input-button-disabled-color: var(--button-disabled-bg);
	--stake-input-bg-color: rgba(var(--primary-accent-grey-light), 1);
	--stake-input-text-color: rgba(var(--font-light), 1);
	--stake-input-icon-color: rgba(var(--primary), 1);
	--stake-input-icon-disabled-color: var(--button-disabled-text);

	// Selection Button
	--selection-button-color: rgba(var(--primary-accent-grey-dark), 1);
	--selection-button-color-alternative: rgba(var(--primary-accent-grey-dark), 1);
	--selection-button-odds-text: rgba(var(--font-light), 1);
	--selection-button-selection-text: rgba(var(--font-supportive), 1);
	--selection-button-active-color: rgba(var(--primary), 1);
	--selection-button-active-text-color: var(--button-primary-text);
	--selection-button-active-hover-bg-color: rgba(var(--primary), 1);
	--selection-button-hover-bg-color: rgba(var(--primary), 1);
	--selection-button-hover-text-color: rgba(var(--font-dark), 1);
	--selection-button-disabled-bg-color: rgba(var(--base-09), 1);
	--selection-button-disabled-text-color: rgba(var(--font-disabled), 1);

	// New Game Card
	--gameCardTitleDetailColor: var(--base-02);
	--gameCardTitleDetailFont: var(--label-regular);
	--gameCardJackpotBg: rgba(var(--primary-accent-green-light), 1);
	--gameCardJackpotText: rgba(var(--font-light), 1);
	--gameCardJackpotFont: var(--label-bold);
	--gameCardJackpotFontSmall: var(--small-bold);
	--gameCardDetailBg: var(--base-11);
	--gameCardTitle: var(--base-02);
	--gameCardTitleSmall: var(--base-06);
	--gameCardWinnerBg: var(--base-11);
	--gameCardDotBg: var(--primary-accent-green);

	// Game Info
	--gameInfoBg: var(--primary-accent-grey);
	--gameInfoTitleName: var(--font-light);
	--gameInfoTitleProvider: var(--font-supportive);
	--gameInfoLoveButton: var(--primary-accent-grey-dark);
	--gameInfoLoveIcon: var(--primary-accent-green-light);
	--gameInfoDescText: var(--font-light);
	--gameInfoButtonBorder: var(--primary-accent-green);
	--gameInfoButtonFont: var(--h4-bold);
	--gameInfoButtonText: var(--font-brand-01);
	--gameInfoDetailBg: var(--primary-accent-grey);
	--gameInfoDetailTitle: var(--base-06);
	--gameInfoDetailAmount: var(--font-light);
	--gameInfoPlayRealButtonBg: var(--primary-accent-green);
	--gameInfoPlayRealButtonText: var(--font-dark);
	--game-info-exit-bg-color: rgba(var(--primary-accent-grey), 0.25);
	--game-info-exit-icon-color: rgba(var(--base-01), 1);

	// Explore Category Carousel
	--category-arrow-icon-color: rgba(var(--primary-accent-green), 1);
	--category-arrow-bg: var(--primary-accent-grey);
	--category-title-color: var(--base-01);
	--category-link-color: var(--font-brand-01);

	// Explore Category Page
	--category-page-bg: var(--base-10);
	--category-page-title-color: var(--base-01);
	--category-exit-icon-color: rgba(var(--primary-accent-green), 1);

	//Slider input
	--slider-empty-track: var(--base-10);
	--bonus-slider-modal-bg: rgba(var(--base-11), 1);
	// Transparent bg
	--background-alpha: rgba(var(--base-10), 0.85);

	// Split view
	--background-split-view: rgba(var(--base-10), 1);

	// Lotteries
	--lotteries-cash-svg-background: rgba(var(--base-09), 1);
	--lotteries-cash-svg-fill: rgba(var(--base-02), 1);

	// Skeleton placeholders
	--skeleton-placeholder-bg: rgba(var(--base-07), 1);
	--skeleton-placeholder-bg-gradient: linear-gradient(
		-90deg,
		var(--skeleton-placeholder-bg) 0%,
		rgba(var(--base-08), 0.6) 50%,
		var(--skeleton-placeholder-bg) 100%
	);

	// Shop / Promotions
	--shop-points-balance-bg: rgba(var(--primary-accent-grey), 1);
	--shop-points-balance-button-bg: rgba(var(--primary-accent-green), 1);
	--shop-points-balance-button-text: rgba(var(--font-dark), 1);
	--shop-points-faq-button: rgba(var(--primary-accent-green), 1);
	--shop-info-banner-bg: green;
	--bonus-item-detail-bg: rgba(var(--primary-accent-grey), 1);
	--bonus-item-foreground-text: rgba(var(--font-light), 1);
	--bonus-item-detail-close: rgba(var(--base-01), 1);

	// Bonus
	--bonus-detail-pill-bg: rgba(var(--primary-accent-green), 1);
	--bonus-welcome-package-item-bg: rgba(var(--primary-accent-grey-light), 1);

	// Bonus Welcome Package
	--bonus-welcome-package-icon-color: rgba(var(--primary-accent-green-light), 1);
	--bonus-welcome-package-item-read-more-button-box: rgb(var(--primary-accent-grey-dark));
	--bonus-welcome-package-light-text-color: rgba(var(--font-supportive), 1);
	--bonus-welcome-package-nested-collapse-bg: rgba(var(--primary-accent-grey), 1);

	// Active bonus
	--active-bonus-progress-lane: rgba(var(--base-11), 1);
	--active-bonus-progress-bar: rgba(var(--primary-accent-green), 1);

	// Free to play
	--free-top-play-card-overlay: rgba(var(--opacity-overlay), 0.5);
	--free-to-play-icon-color: rgba(var(--font-brand-light), 1);
	--free-to-play-no-items-bg-color: rgba(var(--base-08), 1);
	--free-to-play-carousel-arrow-color: rgba(var(--primary-accent-green), 1);

	// Pre Selected Bonus from Promo Hub
	--pre-selected-bonus-bg-color: rgba(var(--base-10));
	--pre-selected-bonus-bg-header: rgba(var(--base-02));
	--pre-selected-bonus-bg-small: rgba(var(--base-06));

	// Filter pills
	--filter-pills-bg: rgba(var(--base-10), 1);
	--filter-pills-bg-active: rgba(var(--primary-accent-green-light), 1);
	--filter-pills-text-color: rgba(var(--primary-accent-green-light), 1);
	--filter-pills-text-color-active: rgba(var(--font-dark), 1);

	// sub navigation ( just below the top navigation )
	--subnavigation-bar-bg: rgba(var(--base-10), 1);
	--subnavigation-item-button-bg: rgba(var(--base-08), 1);
	--subnavigation-item-text-color: rgba(var(--primary-accent-green-light), 1);

	// Submenu items
	--submenu-bg--desktop: var(--filter-bg);
	--submenu-item-bg--desktop: rgba(var(--primary-accent-grey), 1);
	--submenu-item-bg-active--desktop: rgba(var(--primary-accent-green-light), 1);
	--submenu-item-text-color--desktop: rgba(var(--font-light), 1);
	--submenu-item-text-color-active--desktop: rgba(var(--font-dark), 1);
	--submenu-item-border-color--desktop: transparent;
	--submenu-item-border-color-active--desktop: transparent;
	--submenu-item-bg: rgba(var(--primary-accent-grey), 1);
	--submenu-item-bg-active: rgba(var(--primary-accent-green-light), 1);
	--submenu-item-text-color: rgba(var(--font-light), 1);
	--submenu-item-text-color-active: rgba(var(--font-dark), 1);
	--submenu-item-border-color: transparent;
	--submenu-item-border-color-active: transparent;

	//--policy-version-text: defaults to --language-text
	--cookie-toggle-bg-on: rgba(var(--primary-accent-green-light), 1);
	--cookie-toggle-bg-off: rgba(var(--primary-accent-grey-light), 1);
	--cookie-toggle-knob-on: rgba(var(--primary-accent-green), 1);
	--cookie-toggle-knob-off: rgba(var(--primary-accent-green), 1);
	--cookiebanner-wrapper-bg: rgba(var(--primary-accent-grey), 1);
	--cookie-policy-footer-bg: rgba(var(--primary-accent-grey), 1);
	--cookie-policy-footer-wrap-divider: var(--base-09);
	//--cookie-footer-policy-text: defaults to --base-01
	--cookie-footer-wrap-big-bg: rgba(var(--primary-accent-grey), 1);
	--footer-wrapper-big-divider: var(--primary-accent-grey);
	--cookie-footer-wrap-small-bg: rgba(var(--base-10), 1);
	--footer-wrapper-small-divider: var(--primary-accent-grey);
	--cookie-accept-all-text-big: rgba(var(--font-dark), 1);
	--cookie-accept-all-text-small: rgba(var(--font-dark), 1);
	--cookie-save-settings-btn-text: rgba(var(--font-dark), 1);
	--cookie-banner-text: rgba(var(--font-supportive), 1);
	--cookie-accept-settings-text: rgba(var(--font-dark), 1);
	--cookie-accept-settings-small-text: rgba(var(--font-dark), 1);
	--cookie-save-settings-btn-bg: rgba(var(--primary-accent-green), 1);
	--cookie-small-save-settings-btn-bg: rgba(var(--primary-accent-green), 1);
	--cookie-small-save-settings-btn-text: rgba(var(--font-dark), 1);
	--cookie-link-supportive: rgba(var(--font-supportive), 1);
	--cookie-banner-logo: var(--page-head-logo);

	// propose-locale
	//--propose-locale-text: defaults to --base-01;// UI Button dots
	--propose-locale-head-bg: var(--primary-accent-grey-light);
	--propose-locale-border: var(--opacity-overlay);

	//Scroll Down Overlay - SGA Sportsbook Relieve
	--scrollDownContainerBg: linear-gradient(0deg, #4c4f58 0%, #2c2c2d 53.88%, rgba(56, 58, 66, 0%) 77.78%);

	// WeSpin
	--basic-horizontal-card: rgba(var(--base-10), 1);
	--basic-horizontal-card-button-wrapper-bg: rgba(var(--base-08), 1);
	--basic-horizontal-card-button-wrapper-border: rgba(var(--primary-accent-green), 1);
	--basic-horizontal-card-button-svg-fill: rgba(var(--primary-accent-green), 1);
	// --we-spin-clip-card-bg: var(--card-bg);
	// --we-spin-clip-card-text: rgba(var(--base-06), 1);
	// --we-spin-clip-card-title: rgba(var(--base-10), 1);
	--we-spin-dashboard-container-bg: rgba(var(--base-10), 1);
	--we-spin-dashboard-event-detail-border-color: rgba(var(--base-08), 1);
	--we-spin-dashboard-event-detail-title: rgba(var(--font-light), 1);
	--we-spin-dashboard-event-detail-text: rgba(var(--font-light), 1);
	// --we-spin-dashboard-event-detail-view-button: var(--primary-accent-green-light);
	// --we-spin-event-detail-view-starts-in: defaults to var(--pending);
	// --we-spin-dashboard-settings-link: defaults to rgba(var(--primary-accent-green), 1);
	--we-spin-dashboard-switch-description-color: rgba(var(--base-06), 1);
	--we-spin-dashboard-switch-label-disabled: rgba(var(--font-light), 1);
	--we-spin-dashboard-switch-toggle-disabled-bg: rgba(var(--base-07), 1);
	--we-spin-dashboard-switch-toggle-disabled-knob-bg: rgba(var(--base-07), 1);
	--we-spin-dashboard-switch-toggle-off-bg: rgba(var(--base-07), 1);
	--we-spin-dashboard-switch-toggle-off-knob-bg: rgba(var(--primary-accent-green), 1);
	--we-spin-dashboard-switch-toggle-on-bg: rgba(var(--primary-accent-green-light), 1);
	--we-spin-dashboard-switch-toggle-on-knob-bg: rgba(var(--primary-accent-green), 1);
	// --we-spin-game-room-buyin-disabled-bg: defaults to rgba(var(--base-12), 1);
	// --we-spin-livestream-stats-bg: defaults to rgba(var(--base-08), 1);
	// --we-spin-next-session-top-text-color: defaults to rgba(var(--base-02), 1);
	// --we-spin-next-session-middle-text-color: defaults to rgba(var(--primary-accent-green), 1);
	--we-spin-next-session-bottom-text-color: rgba(var(--font-supportive), 1);
	--we-spin-gameroom-buyin-stream-bg-color: var(--sidebar-bg);
	// --we-spin-preview-stream-bg-color: var(--sidebar-bg);
	--we-spin-search-bg: rgba(var(--base-10), 1);
	--we-spin-search-text: rgba(var(--base-04), 1);
	--we-spin-tutorial-card-bg-color: var(--base-12);
	--we-spin-tutorial-card-bg-gradient: none;
	// --we-spin-tutorial-gradient-start: 60%;

	// UI package variables
	// ====================
	--scroll-area-scroll-indicator-color: var(
		--base-10
	); // Intentionally set this as rgb because we need to be able to use different alpha values.

	--floating-element-bg-color: rgba(var(--primary-accent-grey), 1);
	--floating-element-text-color: rgba(var(--base-02), 1);

	// Date picker
	--date-picker-background: rgba(var(--primary-accent-grey-dark), 1);
	--date-picker-outline: rgba(var(--primary-accent-grey-light), 1);
	--date-picker-selected: rgba(var(--primary), 1);
	--date-picker-text: rgba(var(--font-light), 1);
	--date-picker-text-supportive: rgba(var(--font-supportive), 1);
	--date-picker-text-on-selected: rgba(var(--font-dark), 1);

	// Lists
	--list-item-background-color: rgba(var(--base-10), 1);

	// Cards
	--card-bg: rgba(var(--primary-accent-grey), 1);
	--lc-live-casino-card-bg: rgba(var(--base-12), 0.8);
	--lc-card-font-size: 11px;
	// --card-header-caption-font: defaults to --caption;
	// --card-header-caption-font-color: defaults to rgba(var(--base-06));
	// --card-header-title-font: defaults to --label-bold;
	// --card-header-title-font-color: defaults to nothing;
	// --card-media-pill-bg defaults to card-bg
	// --card-media-pill-font defaults to --caption
	// --card-media-pill-border-radius defautls 12px;
	// --card-media-icon-right-bg defaults to --secondary;
	// --card-media-icon-right-fill defaults to --base-01;
	// --card-media-banner-text-bg defaults to --primary-accent-green;
	// --card-media-banner-text defaults to --base-01;
	--card-body-text-font-color: var(--font-supportive);

	// UI Button dots
	--btn-dots: rgba(var(--primary-accent-green), 1);

	// --button-dot-active-bg: defaults to rgba(var(--primary-accent-pink), 1)
	--button-dot-bg: rgba(var(--font-disabled), 1); // defaults to var(--button-secondary-bg, rgba(var(--base-08), 1))

	// Modal
	--modal-wrapper-bg: rgba(var(--primary-accent-grey), 1);
	--modal-wrapper-dark-bg: var(--base-12);

	// quick-deposit-modal
	--qd-modal-contrast-text: var(--primary-accent-green);
	--qd-modal-cancel-text: var(--font-light);

	// new-quick-deposit-modal
	--new-qd--change-method-color: rgba(var(--primary-accent-green), 1);
	--new-qd--amount-input-bg: rgba(var(--primary-accent-grey-dark), 1);
	--new-qd--amount-input-border-color: var(--base-07);
	--new-qd--amount-input-focus-border-color: rgba(var(--primary-accent-green), 1);
	--new-qd--amount-input-focus-text-color: rgba(var(--primary-accent-green), 1);
	--new-qd--amount-input-text-color: rgba(var(--font-light), 1);
	--new-qd--disabled-amount-input-bg: rgba(var(--primary-accent-grey-dark), 1);
	--new-qd--font-color: var(--font-light);
	--new-qd--list-border-color: rgba(var(--primary-accent-grey-light), 1);
	--new-qd--modal-bg: rgba(var(--primary-accent-grey), 1);
	--new-qd--modal-header-close-icon-bg: rgba(var(--base-07), 1);
	--new-qd--modal-header-close-icon-color: rgba(249, 249, 249, 100%);

	// new-quick-deposit-toolbar
	--new-qd--text-color: rgba(var(--font-dark), 1);
	--new-qd--back-button-icon-color: rgba(var(--primary-accent-green), 1);
	--new-qd--button-wrapper-background: rgba(var(--primary-accent-green), 1);
	--new-qd--icon-container-color: rgba(var(--base-11), 1);

	// --action-sheet-button-text-color: defaults to rgba(var(--base-02);
	--action-sheet-button-bg: rgba(var(--base-10), 1);

	//Player Info Screen
	--player-info-bg: rgba(var(--primary-accent-green), 1);
	--player-info-champagne: rgba(var(--base-02), 1);
	--player-info-title: rgba(var(--base-02), 1);
	--player-info-desc: rgba(var(--base-02), 1);
	--player-info-cgr-bg: rgba(var(--primary-accent-green), 1);
	--player-info-cgr-icon: rgba(var(--base-01), 1);
	--player-info-cgr-title: rgba(var(--base-02), 1);
	--player-info-cgr-desc: rgba(var(--base-02), 1);
	--player-info-balance-value: rgba(var(--base-12), 1);
	--player-info-balance-desc: rgba(var(--base-12), 1);

	// Carousel
	--carouselTitleFont: var(--h2);
	--carouselTitleFontLarge: var(--display-3);
	--carouselTitleColor: var(--font-light);
	--carouselDescFont: var(--label-regular);
	--carouselDescFontLarge: var(--h4-bold);
	--carouselDescColor: var(--font-light);
	--carouselDotBg: var(--base-02);
	--carouselDotActiveBg: var(--primary-accent-green);
	--carouselArrow: var(--primary-accent-green);
	--carouselArrowBg: rgba(var(--primary-accent-grey-light), 1);

	//multiLayer Carousel
	--multiLayerCarouselTitleFont: var(--h2);
	--multiLayerCarouselTitleFontLarge: var(--display-2);
	--multiLayerCarouselDescFont: var(--caption);
	--multiLayerCarouselDescFontLarge: var(--h4-regular);
	--multiLayerCarouselButton-border-radius: 100px;
	--multiLayerCarouselButtonBgColor: rgba(var(--primary-accent-green), 1);
	--multiLayerCarouselButtonColor: var(--font-dark);
	--multiLayerCarouselButtonFont: var(--label-bold);
	--multiLayerCarouselButtonFontLarge: var(--h3);
	--bannerTermsConditionsBackground: rgba(var(--primary-accent-grey), 1);
	--bannerTermsConditionsOverlay: rgba(0, 0, 0, 80%);
	--bannerTermsConditionsColor: rgba(var(--font-light), 1);
	--bannerTermsConditionsFont: var(--small);

	// Binary Question
	--binary-question-inner-background: rgba(var(--primary-accent-grey-light), 1);
	--binary-question-divider-color: rgba(var(--primary-accent-grey-dark), 1);

	// tournaments
	--tournaments-sidebar-background: rgba(var(--primary-accent-grey), 1);
	--tournaments-sidebar-surface: rgba(var(--primary-accent-grey-dark), 1);
	--tournaments-sidebar-divider-on-background: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-sidebar-divider-on-surface: rgba(var(--primary-accent-grey), 1);
	--tournaments-text-color-overwrite: unset;
	--tournaments-text-on-tag: #fff;
	--tournaments-button-primary-bg: var(--button-primary-bg);
	--tournaments-rank-highlight: rgba(var(--primary-accent-green-light), 1);
	--tournaments-rank-on-highlight: rgba(var(--font-dark), 1);
	--tournaments-rank-avatar-on-background: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-rank-avatar-on-surface: rgba(var(--primary-accent-grey), 1);
	--tournaments-rank-avatar-on-highlight: rgba(var(--primary-accent-grey), 1);
	--tournaments-rank-avatar-win: rgba(var(--primary-accent-green-light), 1);
	--tournaments-rank-avatar-on-win: rgba(var(--font-dark), 1);
	--tournaments-rank-divider-on-background: rgba(var(--primary-accent-grey-light), 1);
	--tournaments-results-title: rgba(var(--font-light), 1);
	--tournaments-results-trophy: rgba(var(--primary-accent-green-light), 1);
	--tournaments-results-pill-icon: rgba(var(--font-light), 1);
	--tournaments-list-header-button: var(--button-primary-bg);
	--tournaments-list-item-details-button-text: var(--button-primary-bg);
	--tournaments-list-item-details-button-icon: var(--button-primary-bg);
	--tournaments-list-item-timer: rgba(var(--font-supportive), 1);
	--tournaments-modal-header-button-background: rgba(var(--font-light), 0.15);
	--tournaments-details-caption-alt: rgba(var(--font-supportive), 1);
	--tournaments-details-tab-border: rgba(183, 184, 188, 100%);
	--tournaments-label-regular: normal normal 400 1.5rem/1.333 'Open Sauce One', sans-serif;
	--tournaments-label-bold: normal normal 700 1.5rem/1.333 'Open Sauce One', sans-serif;
	--tournaments-caption: normal normal 400 1.2rem/1.333 'Open Sauce One', sans-serif;
	--tournaments-caption-bold: normal normal 700 1.2rem/1.333 'Open Sauce One', sans-serif;
	--tournaments-small: normal normal 400 1.1rem/1.272 'Open Sauce One', sans-serif;
	--tournaments-small-bold: normal normal 700 1.1rem/1.272 'Open Sauce One', sans-serif;
	--tournaments-name-transform: uppercase;

	// quick deposit mobile toolbar
	--quick-deposit-modal-bg: rgba(var(--primary-accent-grey), 1);
	--quick-deposit-amount-input-bg: rgba(var(--primary-accent-grey-dark), 1);
	--quick-deposit-methods-collapse-bg: rgba(var(--primary-accent-grey-dark), 1);
	--quick-deposit--toolbar-background: rgba(var(--base-10), 1);
	--quick-deposit--button-wrapper-background: var(--primary-accent-green);
	--quick-deposit--button-wrapper-border: var(--base-11);
	--quick-deposit--text-color: var(--font-dark);
	--quick-deposit--icon-container-color: var(--base-11);
	--swish-loader-dots: rgba(var(--primary-accent-green), 1);

	// inhouse jackpot
	--inhouse-jackpot--btn-bg-color: var(--base-07);
	--inhouse-jackpot--btn-bg-color--opted-in: var(--base-11);
	--inhouse-jackpot--btn-bg-color--desktop: var(--base-08);
	--inhouse-jackpot--btn-border-color--opted-in: var(--primary-accent-green);
	--inhouse-jackpot--btn-border-color: var(--base-08);
	--inhouse-jackpot--btn-text-color: var(--base-02);
	--inhouse-jackpot--btn-text-color--opted-in: var(--primary-accent-green);
	--inhouse-jackpot--btn-text-color--desktop: var(--inhouse-jackpot--btn-text-color);
	--inhouse-jackpot--icon-container-bg-color--desktop: var(--base-07);
	--inhouse-jackpot--icon-container-bg-color--desktop--opted-in: var(--primary-accent-green);
	--inhouse-jackpot--icon-container-color: var(--base-02);
	--inhouse-jackpot--icon-container-color--desktop: var(--base-02);
	--inhouse-jackpot--icon-container-color--opted-in: var(--primary-accent-green);
	--inhouse-jackpot--icon-container-color--desktop--opted-in: var(--base-11);
	--inhouse-jackpot--modal-background-color: var(--base-11);
	--inhouse-jackpot--modal-header-close-icon-color: var(--base-01);
	--inhouse-jackpot--modal-header-close-icon-color--terms-activated: var(--primary-accent-green);
	--inhouse-jackpot--modal-contribution-info-color: var(--base-02);
	--inhouse-jackpot--modal-content-title-color: var(--base-02);
	--inhouse-jackpot--modal-content-description-color: var(--base-02);
	--inhouse-jackpot--modal-content-show-terms-link-color: var(--primary-accent-green);
	--inhouse-jackpot--modal-content-terms-description-color: var(--base-06);
	--inhouse-jackpot--modal-content-terms-close-color: var(--primary-accent-green);
	--inhouse-jackpot--modal-content-terms-title-color: var(--base-01);
	--inhouse-jackpot--modal-optin-button-font: var(--label-bold);
	--inhouse-jackpot--modal-optin-button-color: var(--base-11);
	--inhouse-jackpot--modal-optout-button-color: var(--primary);
	--inhouse-jackpot--modal-optout-button-border-color: var(--primary);
	--inhouse-jackpot--modal-tiers-text-color: var(--base-01);
	--inhouse-jackpot--win-modal-color: var(--base-02);
	--inhouse-jackpot--win-modal-border-color: var(--primary-accent-green);
	--inhouse-jackpot--win-modal-close-button-font: var(--h3);
	--inhouse-jackpot--win-modal-close-button-color: var(--base-11);

	// inhouse jackpot2 post mvp
	--inhouse-jackpot2--modal-background-color: var(--primary-accent-grey);
	--inhouse-jackpot2--modal-top-right-icon-color: var(--base-01);
	--inhouse-jackpot2--modal-title-icon-color--opted-in: var(--primary-accent-green);
	--inhouse-jackpot2--modal-title-icon-color--opted-out: var(--font-light);
	--inhouse-jackpot2--modal-title-color--opted-in: var(--inhouse-jackpot2--modal-title-icon-color--opted-in);
	--inhouse-jackpot2--modal-title-color--opted-out: var(--inhouse-jackpot2--modal-title-icon-color--opted-out);
	--inhouse-jackpot2--modal-contribution-info-color: var(--font-supportive);
	--inhouse-jackpot2--modal-description-color: var(--font-light);
	--inhouse-jackpot2--modal-show-terms-text-color: var(--inhouse-jackpot2--modal-contribution-info-color);
	--inhouse-jackpot2--modal-show-terms-link-color: var(--primary-accent-green);
	--inhouse-jackpot2--modal-terms-title-color: var(--inhouse-jackpot2--modal-description-color);
	--inhouse-jackpot2--modal-terms-description-color: var(--inhouse-jackpot2--modal-contribution-info-color);
	--inhouse-jackpot2--modal-optin-button-font: var(--h3);
	--inhouse-jackpot2--modal-optin-button-bg-color: var(--primary-accent-green);
	--inhouse-jackpot2--modal-optin-button-border-color: var(--primary-accent-green);
	--inhouse-jackpot2--modal-optin-button-text-color: var(--font-dark);
	--inhouse-jackpot2--modal-optout-button-bg-color: var(--primary-accent-grey);
	--inhouse-jackpot2--modal-optout-button-border-color: var(--primary-accent-green);
	--inhouse-jackpot2--modal-optout-button-text-color: var(--primary-accent-green);
	--inhouse-jackpot2--modal-tiers-text-color: var(--inhouse-jackpot2--modal-contribution-info-color);
	--inhouse-jackpot2--modal-tiers-first-text-color: var(--inhouse-jackpot2--modal-description-color);
	--inhouse-jackpot2--modal-tiers-value-text-color: var(--inhouse-jackpot2--modal-description-color);
	--inhouse-jackpot2--modal-tiers-bottom-border-color: rgba(var(--base-01), 0.1);
	--inhouse-jackpot2--bottom-bar-wrapper-bg-color: rgba(var(--base-12), 0);
	--inhouse-jackpot2--bottom-bar-container-bg-color--opted-in: rgba(var(--primary-accent-green), 0.15);
	--inhouse-jackpot2--bottom-bar-container-bg-color--opted-out: rgba(var(--font-light), 0.1);
	--inhouse-jackpot2--bottom-bar-icon-color--opted-in: var(--inhouse-jackpot2--modal-title-icon-color--opted-in);
	--inhouse-jackpot2--bottom-bar-icon-color--opted-out: var(--inhouse-jackpot2--modal-title-icon-color--opted-out);
	--inhouse-jackpot2--bottom-bar-title-color--opted-in: var(--inhouse-jackpot2--modal-title-icon-color--opted-in);
	--inhouse-jackpot2--bottom-bar-title-color--opted-out: var(--inhouse-jackpot2--modal-title-icon-color--opted-out);
	--inhouse-jackpot2--bottom-bar-tier-name--opted-in: rgba(var(--primary-accent-green), 0.5);
	--inhouse-jackpot2--bottom-bar-tier-name--opted-out: rgba(var(--font-light), 0.5);
	--inhouse-jackpot2--bottom-bar-optin-button-bg-color: var(--primary-accent-green);
	--inhouse-jackpot2--bottom-bar-optin-button-border-color: var(--primary-accent-green);
	--inhouse-jackpot2--bottom-bar-optin-button-text-color: var(--font-dark);
	--inhouse-jackpot2--bottom-bar-optout-button-bg-color: var(--inhouse-jackpot2--bottom-bar-background-color);
	--inhouse-jackpot2--bottom-bar-optout-button-border-color: var(--primary-accent-green);
	--inhouse-jackpot2--bottom-bar-optout-button-text-color: var(--primary-accent-green);

	// casino filters
	--casino-filters-button-color: var(--font-light);
	--casino-filters-button-color--active: var(--font-dark);
	--casino-filters-sorting-option-font: var(--label-bold);
	--expanded-filters-exit-button-bg-color: var(--font-light);
	--expanded-filters-exit-button-icon-color: var(--font-light);
	--expanded-filters-group-clear-text-color: var(--font-brand-01);
	--expanded-filters-button-background: var(--base-10);
	--expanded-filters-button-icon-background: var(--base-08);
	--expanded-filters-button-icon-color: var(--font-light);
	--expanded-filters-button-icon-color--active: var(--primary);

	// BankID
	--bank-id-legend-color: var(--font-supportive);

	// Bankid middle-screen
	--bankid-popup-bg: rgba(var(--base-10), 1);

	// BankID loading screen
	--bankid-loader-bg: rgba(var(--primary-accent-grey), 0.98);
	--bankid-loader-middle-logo-color: rgba(var(--base-11), 1);
	--bankid-loader-title: rgba(var(--base-01), 1);
	--bankid-loader-desc: rgba(var(--font-supportive), 1);
	--bankid-loader-dots: rgba(var(--primary-accent-green), 1);

	// Bankid QRCode
	--qrcode-instructions-number: rgba(var(--base-10), 1);
	--qrcode-inactive-tab: rgba(var(--base-06), 1);
	--qrcode-image-inner-corner: rgba(var(--primary-accent-green), 1);
	--qrcode-image-outer-corner: rgba(var(--base-01), 1);
	--qrcode-image-background: rgba(var(--base-08), 1);
	--qrcode-image-foreground: rgba(var(--base-01), 1);

	// Communications popup
	--comm-popup-close-btn: rgba(var(--primary-accent-green), 1);

	// Cookie Banner
	--cookie-policy-link: rgba(var(--primary-accent-green), 1);

	// Stepper (registration wizard)
	--stepper-active: rgba(var(--primary-accent-green-light), 1);
	--stepper-active-text: rgba(var(--base-02), 1);
	--stepper-inactive: rgba(var(--base-07), 1);
	--stepper-inside-text: var(--font-light);
	--custom-show-more-btn-color: rgba(var(--primary-accent-green), 1);
	--custom-stepper-active-color: var(--font-light);
	--disabled: rgba(var(--font-supportive), 1);

	// Campaign-registration
	--registration-bg: rgba(var(--base-11), 1);
	--collapse-view-chevron: rgba(var(--primary-accent-green), 1);

	// Limits
	--limits-notification-icon: var(--primary-accent-green-light);
	--opening-at-clock-icon: rgba(var(--base-02), 1);

	//notification-warning-banner
	--notif-banner-bg: rgba(var(--base-10), 1);
	--notif-banner-icon: rgba(var(--primary-accent-green-light), 1);

	// Responsible Gaming
	--time-limit-reached-label-color: var(--font-supportive);
	--time-limit-reached-timer-color: var(--font-light);

	// Credit Card
	--credit-card: rgba(var(--primary-accent-grey-dark), 1);
	--cc-indicator: rgba(var(--base-08), 1);
	--cc-indicator-active: rgba(var(--primary-accent-green), 1);
	--cc-card-add-btn: rgba(var(--base-10), 1);
	--cc-card-add-btn-text: rgba(var(--primary-accent-green), 1);
	--verification-message-verified-color: var(--primary);
	--verification-message-unverified-color: var(--base-06);

	// Bankid popup
	--bankid-popup-button: rgba(var(--primary), 1);
	--bankid-popup-transparent-button: rgba(var(--primary-accent-green), 1);
	--bankid-popup-background: rgba(var(--base-10), 1);
	--bankid-popup-cancel: rgba(var(--active-players-color), 1);
	--bankid-popup-font-cancel: var(--label-regular);
	--bankid-popup-text: rgba(var(--font-supportive), 1);

	// registration complete w/o deposit

	--reg-complete-popup-wrapper: rgba(var(--primary-accent-grey), 1);

	// New input colors
	--input-background-color: var(--primary-accent-grey-dark);
	--input-disabled-background-color: var(--primary-accent-grey);
	--input-border-color: var(--primary-accent-grey-light);
	--input-border-hover-color: var(--primary-accent-green);
	--input-border-focus-color: var(--primary-accent-green);
	--input-border-disabled-color: var(--base-09);
	--input-border-error-color: var(--error);
	--input-placeholder-color: var(--font-supportive);
	--input-placeholder-disabled-color: var(--base-08);
	--input-font-color: var(--font-light);
	--input-font-focus-color: var(--primary-accent-green);
	--input-font-disabled-color: var(--base-08);
	--input-error-color: var(--error);
	--input-helper-text-color: var(--font-light);
	--select-arrow-color: var(--primary-accent-green);
	--toggle-group-selected-color: var(--font-brand-01);
	--toggle-group-color: var(--base-05);

	// Thunderbite
	--thunderbite-modal-bg: rgba(var(--base-12), 0.8);
	--thunderbite-close: rgba(var(--primary-accent-green), 1);

	//MitID
	--mit-id-logo: rgba(var(--base-02), 1);

	//MitID sidebar
	--sidebar-wrapper-bg: rgba(var(--primary-accent-grey), 1);
	--sidebar-close-btn: rgba(var(--primary-accent-green), 1);
	--mitid-button-text-color: rgba(var(--font-dark), 1);
	--flow-blocker-cancel-cta: rgba(var(--active-players-color), 1);

	// Support nav
	--opacity-overlay: var(--base-12);

	// Ingame Desktop view
	--ingame-nav-title: rgba(var(--font-light), 1);
	--ingame-jurisdiction-color: rgba(var(--font-light), 1);
	--ingame-nav-icons: rgba(var(--primary-accent-green), 1);

	// Social media
	--social-media-button-background: var(--social-media-light-text-bg);
	--social-media-button-background-icon: var(--social-media-light-text-bg);
	--social-media-button-text: var(--social-media-light-text);

	//Onfido
	--onfido-font-family: 'Open Sauce One', sans-serif;
	--onfido-icon-bg: rgba(var(--primary-accent-green-light), 1);
	--onfido-bg: rgba(var(--primary-accent-grey), 1);
	--onfido-content-color: rgba(var(--font-light), 1);
	--onfido-btn-bg: rgba(var(--primary), 1);
	--onfido-title-font-size: 2.2rem; //--h2 font fize
	--onfido-subtitle-font-size: 1.5rem; //--label-regular font fize
	--onfido-body-font-size: 1.5rem; //--label-regular font fize
	--onfido-secondary-btn-border: rgba(var(--primary), 1);
	--onfido-secondary-btn-text: rgba(var(--primary), 1);
	--onfido-btn-link: rgba(var(--primary), 1);
	--onfido-pill-bg: rgba(var(--primary), 1);
	--onfido-surface-border: 0;

	//no other way to override. this was generated from https://yoksel.github.io/url-encoder/
	--onfido-back-icon-url: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.60941 13.724L5.21875 9.33335H16.0001V6.66669H5.21875L9.60941 2.27602L7.72408 0.390686L0.114746 8.00002L7.72408 15.6094L9.60941 13.724Z' fill='%2339F56A'/%3E%3C/svg%3E%0A");
	--onfido-help-icon-url: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%0A%3E%3Ctitle%3EGroup%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3Cpath id='a' d='m8.6667 9.9327c1.5507-0.3189 2.6644-1.6828 2.6667-3.266 0-1.838-1.4953-3.3333-3.3333-3.3333s-3.3333 1.4953-3.3333 3.3333h1.3333c0-1.1027 0.89733-2 2-2s2 0.89733 2 2c0 1.1027-0.89733 2-2 2h-0.66667v2h1.3333v-0.734zm-1.3333 2.0673h1.3333v1.3333h-1.3333v-1.3333z' /%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-173 -346)'%3E%3Cg transform='translate(173 184)'%3E%3Cg transform='translate(0 160)'%3E%3Cg transform='translate(0 2)'%3E%3Ccircle cx='8' cy='8' r='8' fill='%2339F56A' /%3E%3Cmask fill='white'%3E%3Cuse xlink:href='%23a' /%3E%3C/mask%3E%3Cuse fill='%23000000' fill-rule='evenodd' xlink:href='%23a' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
	--onfido-camera-icon-url: url("data:image/svg+xml,%3Csvg width='56' height='46' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 24c0-6.617 5.383-12 12-12s12 5.383 12 12-5.383 12-12 12-12-5.383-12-12zM36.036 2H19.964l-4.456 4.842H4.87C3.28 6.842 2 8.116 2 9.684v31.474C2 42.726 3.281 44 4.87 44h46.26c1.59 0 2.87-1.274 2.87-2.842V9.684c0-1.568-1.281-2.842-2.87-2.842H40.492L36.036 2z' stroke='rgb(57 245 106)' stroke-width='4' fill='none' /%3E%3C/svg%3E");
	--onfido-sms-icon-url: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 14 13' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%0A%3E%3Ctitle%3Emessage-detail%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3Cpath id='a' d='m14 2h-12c-0.36819 0-0.66667 0.29848-0.66667 0.66667v8c0 0.36819 0.29848 0.66667 0.66667 0.66667h2.6667v2.6667c-5.9623e-4 0.26991 0.16178 0.5135 0.41115 0.61678s0.53642 0.045842 0.72685-0.14545l3.138-3.138h5.0573c0.36819 0 0.66667-0.29848 0.66667-0.66667v-8c0-0.36819-0.29848-0.66667-0.66667-0.66667zm-0.66667 8h-4.6667c-0.17686-2.565e-4 -0.34651 0.070051-0.47133 0.19533l-2.1953 2.1953v-1.724c0-0.36819-0.29848-0.66667-0.66667-0.66667h-2.6667v-6.6667h10.667v6.6667zm-9.3333-5.3333h8v1.3333h-8v-1.3333zm0 2.6667h4v1.3333h-4v-1.3333z' /%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-142 -518)'%3E%3Cg transform='translate(141 512)'%3E%3Cg transform='translate(0 4)'%3E%3Cmask fill='white'%3E%3Cuse xlink:href='%23a' /%3E%3C/mask%3E%3Cuse fill='%2339F56A' fill-rule='evenodd' xlink:href='%23a' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
	--onfido-link-icon-url: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 12 12' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%0A%3E%3Ctitle%3Emessage-detail%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3Cpath id='a' d='m5.6433 7.5287c0.75533-0.75533 2.0727-0.75533 2.828 0l0.47133 0.47133 0.94267-0.94267-0.47133-0.47133c-0.62867-0.62933-1.466-0.97667-2.3567-0.97667-0.89067 0-1.728 0.34733-2.3567 0.97667l-1.4147 1.414c-1.2988 1.303-1.2988 3.411 0 4.714 0.6246 0.62549 1.4727 0.97626 2.3567 0.97467 0.88419 0.0018349 1.7326-0.34896 2.3573-0.97467l0.47133-0.47133-0.94267-0.94267-0.47133 0.47133c-0.78214 0.77864-2.0465 0.77864-2.8287 0-0.77937-0.78184-0.77937-2.0468 0-2.8287l1.4147-1.414zm5.656 1.8853 1.4147-1.414c1.2988-1.303 1.2988-3.411 0-4.714-1.3027-1.2995-3.4113-1.2995-4.714 0l-0.47133 0.47133 0.94267 0.94267 0.47133-0.47133c0.78214-0.77864 2.0465-0.77864 2.8287 0 0.77937 0.78184 0.77937 2.0468 0 2.8287l-1.4147 1.414c-0.75533 0.75533-2.0727 0.75533-2.828 0l-0.47133-0.47133-0.94267 0.94267 0.47133 0.47133c0.62867 0.62933 1.466 0.97667 2.3567 0.97667 0.89067 0 1.728-0.34733 2.3567-0.97667z' /%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-296 -518)'%3E%3Cg transform='translate(141 512)'%3E%3Cg transform='translate(153)'%3E%3Cg transform='translate(0 4)'%3E%3Cmask fill='white'%3E%3Cuse xlink:href='%23a' /%3E%3C/mask%3E%3Cuse fill='%2339F56A' fill-rule='evenodd' xlink:href='%23a' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
	--onfido-qr-code-icon-url: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Ctitle%3Eicon/qr/primary%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-149 -518)' fill='%2339F56A'%3E%3Cg transform='translate(149 518)'%3E%3Cpath id='a' d='m0 0h10v10h-10v-10zm1 1v8h8v-8h-8z' /%3E%3Cpath d='m6 0h4v4h-4v-4zm1 1v2h2v-2h-2z' /%3E%3Cpath d='m0 0h4v4h-4v-4zm1 1v2h2v-2h-2z' /%3E%3Cpath d='m0 6h4v4h-4v-4zm1 1v2h2v-2h-2z' /%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
	--onfido-success-icon-url: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 13 11' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Ctitle%3Echeckmark-green%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-409 -442)' fill='%2339F56A'%3E%3Cpath d='m419.17 442.52-5.8676 6.2265-1.4666-1.5566c-0.64816-0.68525-1.699-0.68525-2.3468 0-0.64816 0.68948-0.64816 1.8062 0 2.4914l2.6402 2.8045c0.64816 0.68948 1.6986 0.68948 2.3468 0l7.0408-7.4743c0.64816-0.68948 0.64816-1.8062 0-2.4914-0.64816-0.68948-1.6986-0.68948-2.3468 0z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
	--onfido-camera-tick-icon-url: url("data:image/svg+xml,%3Csvg width='109' height='96' viewBox='0 0 109 96' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_803_38068)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M62.9027 5.46938e-06C64.2933 -0.00200589 65.6272 0.550811 66.6087 1.53589L80.7985 15.7257H99.5959C102.491 15.7257 104.838 18.0726 104.838 20.9676L104.837 50.846H94.354L94.354 26.2095H78.6284C77.2378 26.2115 75.9039 25.6587 74.9223 24.6736L60.7326 10.4838H44.1053L29.9155 24.6736C28.9352 25.6591 27.6038 26.2095 26.2095 26.2095H10.4838V83.8703L57.136 83.87V94.354L5.24189 94.3541C2.41925 94.3541 0.117686 92.1231 0.00437228 89.3282L0 89.1122V20.9676C0 18.0726 2.34687 15.7257 5.24189 15.7257H24.0393L38.2291 1.53589C39.2106 0.550811 40.5445 -0.00200589 41.9351 5.46938e-06H62.9027ZM52.4189 31.4514C63.7833 31.4514 73.3865 41.0545 73.3865 52.4189C73.3865 63.7833 63.7833 73.3865 52.4189 73.3865C41.0545 73.3865 31.4513 63.7833 31.4513 52.4189C31.4513 41.0545 41.0545 31.4514 52.4189 31.4514ZM52.4189 41.9351C46.8363 41.9351 41.9351 46.8363 41.9351 52.4189C41.9351 58.0015 46.8363 62.9027 52.4189 62.9027C58.0015 62.9027 62.9027 58.0015 62.9027 52.4189C62.9027 46.8363 58.0015 41.9351 52.4189 41.9351Z' fill='%23C0F9C8'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M78.6284 81.1759L71.8506 74.3982L64.4386 81.8102L78.6284 96L108.544 66.0845L101.132 58.6725L78.6284 81.1759Z' fill='%23F9F9F9'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_803_38068'%3E%3Crect width='109' height='96' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");

	// Marketplace &
	--list-detail-value: rgba(var(--font-supportive), 1);
	--marketplace-success-text-color: rgba(var(--base-01), 1);
	--marketplace-success-subtext-color: rgba(var(--font-supportive), 1);
	--marketplace-success-bg-color: rgba(var(--base-10));
	--marketplace-success-btn-bg: rgba(var(--base-01), 0.25);
	--marketplace-continue-btn-text-color: rgba(var(--primary), 1);
	--marketplace-success-main-icon: var(--section-head-main-icon);
	--marketplace-success-close-icon-color: rgba(var(--primary), 1);
	--marketplace-success-balance-color: rgba(var(--font-supportive));
	--marketplace-progress-bg: rgba(var(--primary-accent-grey), 1);
	--marketplace-progress-bar-bg: rgba(var(--primary-accent-green-light), 1);
	--marketplace-ticket-type-button-bg: rgba(var(--primary-accent-green), 1);
	--marketplace-ticket-type-button-color: rgba(var(--base-01));
	--marketplace-points-bg: rgba(var(--primary-accent-green), 1);
	--marketplace-points-text: rgba(var(--font-dark), 1);
	--marketplace-sale-bg: rgba(var(--primary-accent-green-light), 1);
	--marketplace-sale-text: rgba(var(--font-dark), 1);
	--marketplace-sale-text-bg: rgba(var(--base-01), 0.1);
	--marketplace-type-text: rgba(var(--primary-accent-green), 1);
	--marketplace-info-text: rgba(var(--font-light), 1);
	--marketplace-wagering-text: rgba(var(--font-light), 1);
	--marketplace-card-bg: rgba(var(--primary-accent-grey), 1);
	--marketplace-specials-bg: rgba(var(--primary-accent-grey), 1);
	--marketplace-specials-card-bg: rgba(var(--primary-accent-grey-light), 1);

	//Mentor UI Gaming Habits -- Playing Behavior box --
	--button-selection-days-select-color: rgba(var(--primary-accent-green-light), 1);
	--button-selection-days-color: rgba(var(--base-10), 1);
	--game-type-icon-color: var(--primary-accent-green-light);
	--your-behaviour-box-chart: rgba(var(--primary-accent-green-light), 1);
	--comparable-players-box-chart: rgba(var(--primary-accent-grey-dark), 1);
	--box-info-data-player: rgba(var(--base-10), 1);
	--playing-behaviour-pills-text: rgba(var(--primary-accent-green-light), 1);
	--playing-behaviour-pills-text-selected: rgba(var(--font-dark), 1);
	--playing-behaviour-percentage-text: var(--base-05);
	--playing-behaviour-description-text: rgba(var(--font-supportive), 1);
	--vertical-line-others: rgba(var(--primary));

	// Transaction history
	--filter-pills-bg-th: var(--primary-accent-grey-light);
	--filtering-pills-bg: var(--primary-accent-green-light);
	--filtering-pills-disable: var(--base-10);
	--filtering-pills-hover: var(--primary-accent-green-light);
	--filtering-pill-font-color: var(--font-dark);
	--custom-selected-filtering-pill-color: var(--base-01);

	// Game Info
	--game-info-section-background-color: rgba(var(--primary-accent-grey), 1);
	--game-info-separator-color: rgba(var(--primary-accent-grey), 1);
	--game-info-background-color: rgba(var(--primary-accent-grey-dark), 1);
	--game-info-button-background-color: rgba(var(--primary-accent-grey-dark), 1);
	--game-info-card-color: rgba(var(--primary-accent-grey-dark), 1);
	--game-info-menu-background: rgba(var(--primary-accent-grey-dark), 1);
	--game-info-menu-icon-color: rgba(var(--primary), 1);
	--game-info-volatility-icon-color: rgba(var(--primary), 1);
	--game-info-jackpot-amount-text-color: rgba(var(--font-light), 1);
	--game-info-row-value-text-color: rgba(var(--font-light), 1);
	--game-info-name-text-color: rgba(var(--font-light), 1);
	--game-info-provider-text-color: rgba(var(--font-light), 0.6);
	--game-info-collapse-header-text-color: rgba(var(--font-light), 1);
	--game-info-play-button-background-color: rgba(var(--primary), 1);
	--game-info-play-button-color: rgba(var(--font-dark), 1);

	//Transaction link
	--transaction-link-button-text-color: rgba(var(--font-dark), 1);
	--transaction-link-button-border-radius: 26px;

	// Transaction history notification
	--transaction-history-notification-background-color: rgba(var(--primary-accent-grey-dark), 1);
	--transaction-history-notification-icon-color: var(--font-brand-light);

	// Account closure
	--account-closure-reasons-bg: rgba(var(--primary-accent-grey-dark), 1);
	--account-closure-icon-bg: rgba(var(--primary-light), 1);

	// Account exclusion notification
	--account-exclusion-notification-button: var(--primary-accent-green);
	--account-exclusion-notification-bg: rgba(var(--primary-accent-grey-dark), 1);
	--account-exclusion-notification-text: var(--font-light);
	--account-exclusion-notification-icon: rgba(var(--primary-accent-green-light), 1);

	// Enhanced Dropdown
	--enhanced-dropdown-scrollbar-thumb: var(--primary-accent-green);
	--enhanced-dropdown-scrollbar-track: var(--primary-accent-grey-light);

	// Exclusive games
	--exclusive-games-text-transform: uppercase;

	// New change bank bottom popup
	--change-bank-account-popup-close-icon-fill: rgba(249, 249, 249, 100%);
	--change-bank-account-popup-close-icon-bg: rgba(var(--base-01), 0.15);
	--change-bank-account-popup-bg: rgba(var(--base-09), 1);
	--change-bank-account-popup-text-color-light: var(--font-light);
	--change-bank-account-popup-contrast-text: var(--primary-accent-yellow);

	// Communication popup
	--communication-popup-background-color: rgba(var(--primary-accent-grey), 1);
	--communication-popup-title-color: rgba(var(--font-light), 1);

	// Limits container Tabs
	--tabs-bg: var(--button-disabled-bg);
	--active-tab-bg: rgba(var(--base-06));

	//New Navigation
	--hub-link-list-bg: rgba(var(--primary-accent-grey), 1);
	--hub-nested-header-bg: rgba(var(--base-10), 1);
	--hub-nested-header-title: rgba(var(--font-light), 1);
	--hub-nested-header-icon: rgba(var(--primary-accent-green), 1);
	--bottom-nav-bg: rgba(var(--primary-accent-grey), 1);
	--botom-nav-border: rgba(var(--base-07), 1);
	--bottom-nav-label: rgba(var(--font-supportive), 1);
	--bottom-nav-label-active: rgba(var(--primary-accent-green), 1);
	--top-nav-bg: rgba(var(--base-10), 1);
	--top-nav-label: rgba(var(--font-light), 1);
	--top-nav-label-active: rgba(var(--primary-accent-green), 1);
}
